/**
 * @module utils
 */
/** comment to work-around limitation of typedoc module plugin */

// Copyright 2018-2024 Enlightware GmbH, Switzerland

import { createRandomUuidBuffer } from 'utils/uuid-generator';
import { bufferToHex } from 'utils/buffers';
import { CodeVersion } from 'apps/common/version';

export function bootstrapFailed(app: string, error: any) {
	console.error('Error importing `full-app.ts`:', error);
	const element = document.getElementById('fatal-box')!;
	element.innerHTML = 'Unsupported browser or network error, please make sure you use a recent version of Chrome, Firefox, Safari or Edge and try again.';
	element.style.backgroundColor = 'rgb(255, 200, 200)';
	element.style.display = '';
	const msg = (error ? 'Reason: ' + error.reason : 'Error is falsy') + `, with browser "${window.navigator.userAgent}"`;
	const record = [
		`${app}BootstrapFailed`,
		Date.now(),
		bufferToHex(createRandomUuidBuffer()),
		0,
		null,
		{
			codeVersion: CodeVersion,
			msg,
			error: error ? error.toString() : 'Error is falsy',
			stack: error ? error.stack : undefined,
		},
	];
	const blob = new Blob([JSON.stringify(record)], {type : 'application/json'});
	const baseUrl = location.href.replace(/\/[^?#/]*([?#].*)?$/, '');
	navigator.sendBeacon(baseUrl + '/analytics/v0/err', blob);
}
