/**
 * @module full-app
 */
/** comment to work-around limitation of typedoc module plugin */

// Copyright 2018-2024 Enlightware GmbH, Switzerland

import './full-app.scss';

import { loadBrowserLanguage } from 'translator/translator';
import { bootstrapFailed } from 'apps/common/bootstrap-failed';
import { awaitClick } from 'utils/async';

const AgreeKey = 'gc-agree';
const AgreeValue = 'agreed';

function doAgree() {
	localStorage.setItem(AgreeKey, AgreeValue);
}

async function load() {
	try {
		// working around webpack bug with WASM
		// see https://github.com/webpack/webpack/issues/6615
		await import('./full-app');
	} catch (e) {
		bootstrapFailed('create', e);
	}
}

async function askForAgreement() {
	document.getElementById('consent')!.style.display = '';
	document.getElementById('mainmenu-loading')!.style.display = 'none';
	await awaitClick(document.getElementById('consent-button')!);
	doAgree();
	document.getElementById('consent')!.style.display = 'none';
	document.getElementById('mainmenu-loading')!.style.display = '';
	await load();
}

async function init() {
	// hide font loading hack
	document.getElementById('font-loading-hack')!.style.display = 'none';

	// load translations
	loadBrowserLanguage();

	// disable double-click (to prevent zooming on iOS)
	document.ondblclick = (e) => e.preventDefault();

	// agreement by URL
	const url = new URL(window.location.href);
	const agreedByUrl = url.searchParams.get('agreed') !== null;
	if (agreedByUrl) {
		doAgree();
		url.searchParams.delete('agreed')
		history.replaceState('', '', url);
	}

	// agreement by local storage
	const agreedByLocalStorage = localStorage.getItem(AgreeKey) === AgreeValue;

	// decided depending on agreement
	const agreed = agreedByUrl || agreedByLocalStorage;
	if (agreed) {
		await load();
	} else {
		await askForAgreement();
	}
}

void init();
